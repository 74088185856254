import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";
import ProductsInfo from "components/cards/ProductsInfo";
import AddressSearchInput from "components/common/AddressSearchInput";
import Image from 'components/common/Image';
import Faq from 'components/common/Faq';

import faqDataOffer from 'constants/components/accordion-list/plans-pricing-faq';
import { isLpgDemergerOn } from 'constants/global';

import 'styles/pages/plans-pricing.css';


function PlansPricing() {

	return (
		<>
            <SEO title="Natural gas pricing | WA's Local Provider - Kleenheat" 
				 titleBespoke="true"
            	 description="Join WA's local natural gas supplier. Enjoy award-winning local service and ongoing value with Kleenheat. Find a plan to suit your needs today."
        	/>
            <Header />
            <LayoutMain>

				<ProductsInfo panelBackground="mer-bg--primary-cyan"
	            			  panelPadding="mer-py-lg"
							  panelTheme="mer-theme--cyan plans-pricing"
							  panelHeading="Natural gas pricing"
	            			  id="pricing" />

            	<LayoutPanel theme="mer-theme--light"
            				 padding="mer-py-lg"
            				 col="flex-sm-row-reverse"
                			 background="mer-bg--ui-light"
		                	 id="service-availability" >
	                <div className="container">
	                    <div className="row mer-panel justify-content-center">

	                        <div className="col-10 col-sm-8 col-md-6 col-lg-6 content mer-text--size-lg">
	                           	<h2>Monthly instalments explained</h2>
								<p>Monthly instalments reflect your property’s likely natural gas usage for the three months ahead. In most cases, instalments are based on gas usage at the same time the previous year.</p>
								<p>Your meter is read each quarter as normal, and we use the meter reading to balance your account and calculate your next instalment amount.</p>
	                        </div>

	                        <div className="mer-panel__images col-8 col-sm-4 col-md-5 col-lg-2 offset-sm-1">
	                            <Image className="mer-panel__image"
	                                 src="/icons/flowmoji/flowmoji-instalments.svg"
	                                 alt="Flowmoji instalments"
	                          />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

				<Faq panelBackground="mer-bg--ui-light"
		  			 panelPadding="mer-py-lg"
		  			 id="faq"
		  			 data={faqDataOffer} />

			 	{/* address search */}
            	<LayoutPanel theme="mer-theme--light"
            				 padding="mer-py-lg mer-border--top"
            				 col="flex-sm-row-reverse"
                			 background="mer-bg--gs-grey-lighter"
		                	 id="service-availability" >
	                <div className="container">
	                    <div className="row mer-panel justify-content-center">

	                        <div className="col-10 col-md-8">
	                           	<form className="mer-text--size-lg">
	                                <fieldset>
	                                    <legend>Check availability at your address</legend>
	                                    <div className="row">
	                                        <div className="col-12">
	                                            <AddressSearchInput searchButtonText="Check address" isSsfRedirect={isLpgDemergerOn} />
	                                        </div>
	                                    </div>
	                                </fieldset>
	                            </form>
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default PlansPricing;