import React, { createRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { LinkModal } from 'kh-common-components';
import { modalContents } from '../../constants/modal-content';

function ProductSelect (props) {

    let defaultCarouselClass = 'mer-media-card-carousel mer-media-card-carousel-react container-fluid-constrained';
    let carouselIsHeightEqual = props.equalHeight !== undefined && props.equalHeight  ? ' mer-slick-slider--height-equal' : '';
    let carouselClass = defaultCarouselClass + carouselIsHeightEqual;

    const settings = {
        // rows: 0, // causes memory leak
        // lazyLoad: true,
        arrows: false,
        dots: true,
        infinite: false,
        swipeToSlide: true,
        draggable: true,
        mobileFirst: true,
        transformEnabled: false,
        useTransform: false,

        speed: 800,
        slidesToShow: 3,

        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '100px',
  
        dotsClass: 'd-flex slick-dots',
        className: carouselClass,

        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '1px'
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '200px'
                }
            },
                {
                breakpoint: 860,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 639, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 550, // 639 less
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]
    };


    let mediaCardRef = [];

    useEffect(() => {
        mediaCardRef.forEach(referenceItem => {
            if(referenceItem.current) {
                referenceItem.current.parentElement.classList.add('col', 'mer-card', 'mer-card-media', 'mdc-card');
            }
        });
    });

    return (
        <Slider {...settings}>
            {
                props.data.map((plan, index) =>{
                    const newRef = createRef();
                    mediaCardRef.push(newRef);
                    
                    let isStandard = plan.isStandard ? 'standard' : 'non-standard';
                    let discount = plan.isStandard ? plan.onTimePaymentDiscount : plan.discount;
                    let discountDecimalPlace = "";
                    if (!Number.isNaN(discount) && !Number.isInteger(discount)) { // if discount is a number with decimal places
                        // split whole number and decimal place
                        const discountInStr = discount.toString();
                        const dotIndex = discountInStr.indexOf(".");
                        discountDecimalPlace = discountInStr.substring(dotIndex, dotIndex + 2); // only keeping 1 decimal place
                        discount = parseInt(discount); // whole number
                    }
                    
                    return (
                        <div ref={newRef} className="mer-card-media-react__container mer-plan-card mer-card mdc-card text-center py-4" key={index}>
                            <h3 className="mer-plan-card__title mx-3">{plan.name}</h3>
                            <p className="mer-plan-card__description">
                                <span className="mer-plan-card__discount">
                                    {discount}
                                    {discountDecimalPlace !== "" && <span className="mer-plan-card__discount-decimal">.5</span>}
                                    <span className="mer-plan-card__discount-percent">%</span>
                                </span>
                                <span className="mer-plan-card__discount-description">off gas usage charges {plan.onTimePaymentDiscount !== 0 ? "when you pay on time" : "for at least 12 months"}</span>
                            </p>
                            <div className="mer-color--primary-blue mer-font--weight-bold content mt-3 py-2 mer-border--bottom mer-border--top mer-border--color-gs-lighter">
                                {plan.welcomeBonusAvailable ? "$50 welcome bonus" : "Our most popular plan"}
                            </div>
                            <ul className="mer-list mer-color--primary-navy mer-border--bottom mer-border--color-gs-lighter m-0 px-2 py-3 mb-4">
                                {plan.requiresDirectDebit &&
                                <>
                                    <li><strong>Direct debit</strong></li>
                                    <li><strong>Easy monthly instalments</strong></li>
                                    <li><strong>Email bills</strong></li>
                                </>
                                }
                                {!plan.requiresDirectDebit &&
                                <>
                                    <li><strong>Choice of payment method</strong></li>
                                    <li><strong>Quarterly bills</strong></li>
                                    <li><strong>Email or paper bills</strong></li>
                                </>
                                }
                                <li>No setup costs</li>
                                <li>No lock-in contracts</li>
                            </ul>
                            <div className="mx-4">
                                <a href={`/sign-up/product/${plan.key}`} className="plan-cta mer-button mer-button--primary mer-button--size-full-width content mb-3">
                                    Choose plan
                                </a>
                                <div>                  
                                    <small><LinkModal {...props}
                                        modalContents={modalContents}
                                        useModal="true"
                                        title={`${plan.name} terms & conditions`}
                                        // linkClass=""
                                        displayContent={`View plan details and ${isStandard} terms`}
                                        modalTitle={`${plan.name} terms & conditions`}
                                        modalContentName={`plan_details_and_terms_${plan.key}`}
                                        okayButtonLabel="Okay"
                                    /></small>
                                </div>
                            </div>
                        </div>
                    )
                })
            }                
        </Slider>
    );
}

export default ProductSelect;